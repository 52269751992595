import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios';
import 'datatables.net';
import 'datatables.net-bs4';
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
import $ from 'jquery'
import { Card, CardBody } from 'reactstrap';

import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
import { PremisesListData, PremisesListResponse } from '../../../../helpers/response/Admin/PremiseListResponse';
import getStorageData from '../../../../Utils/getStorageData';
import { useDispatch } from 'react-redux';
import { chipLogs } from '../../../../store/slices/APIResponseSlice';
import { useSelector } from 'react-redux';

export interface interfaceDataTable<T> {
    responseType?: T;
    apiURL: string,
    columns: any[],
    btnName?: string,
    title?: string,
    isAddBtnShow?: boolean,
    btnStyle?: any,
    isBtnShow?: boolean,
    secondBtnText?: string,
    secondBtnStyle?: any,
    onClickBtn?: () => void,
    secondBtnOnClick?: () => void,
    handleEditClick?: (data: any) => void,
    handleDeleteClick?: (data: any) => void,
    onDataTableRefresh?: (dataTableId: any) => void,
    idName: string,
    handleColumnClicked?: (data: any) => void | undefined,
    selectedRoomID?: any[],
    paramsId?: any,
    isEntryShow?: boolean,
    isClicked?: boolean,
    setReload?: any,
    onRowToggle?: (rowId: any) => any
    expandedRows?: any[];
}
const ChipDataTable = <T,>({
    responseType,
    title,
    apiURL,
    columns,
    btnName,
    isAddBtnShow,
    btnStyle,
    isBtnShow,
    secondBtnText,
    secondBtnStyle,
    onClickBtn,
    secondBtnOnClick,
    handleEditClick,
    handleDeleteClick,
    onDataTableRefresh,
    idName,
    handleColumnClicked,
    selectedRoomID,
    paramsId,
    isEntryShow,
    isClicked,
    setReload,
    onRowToggle,
    expandedRows
}: interfaceDataTable<T>) => {
    const selectedRooms = useSelector((state: any) => state.APIResponse.selectedRooms);
    console.log("selectedRoomssssss", selectedRoomID);

    // const [data, setData] = useState([]);
    const dispatch = useDispatch();
    const [tableData, setTableData] = useState<PremisesListData[]>([]);
    const [loading, setLoading] = useState(false);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [expandedRowsState, setExpandedRowsState] = useState({});
    const [expandedBlasterState,setExpandedBlasterState] = useState({});
    const [dataReDraw, setDataReDraw] = useState(false);

    console.log("datadata~~~~~~~~~~~~~>", expandedBlasterState);
    const tableRef: any = useRef(null);
    const userType = getStorageData().userType;


    const token = localStorage.getItem("Authorization");

    var initializeTable = 0;

    // console.log("tableRef", tableRef);


    let dataTableInstance: any = null;
    const fetchData = (dataTablesData: any, callback: any) => {
        if (idName === "Chipslist" || idName === "Blasterlist") {
            setReload(false);
        }
        // setLoading(true)
        $('#customLoader').show()
        console.log("dataTablesData", selectedRoomID);
        const reqObj: any = {
            ...dataTablesData,
            ...paramsId,
            roomId: selectedRoomID && selectedRoomID
                .filter((item: any) => item.isChecked)
                .map((item: any) => item.id)
                .join(",")
        }
        axios.post(apiURL && apiURL, reqObj, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        }).then((response) => {
            const res = response.data
            console.log("res", res);
            dispatch(chipLogs({
                totalOfflineTime: res?.totalOfflineTime,
                totalOnlineTime: res?.totalOnlineTime
            }))
            $('#customLoader').hide();
            callback({
                draw: dataTablesData.draw,
                recordsTotal: res.recordsTotal,
                recordsFiltered: res.recordsFiltered, // You might adjust this based on filtered records
                data: res.data,
            });
            if (idName === "Chipslist" || idName === "Blasterlist") {
          
                    setReload(true);
                    // openExpandedRows();
            }
            setTableData(res.data)
        })
            .catch((err) => {
                if (err.response && err.response.status === 401) {
                    // window.localStorage.clear();
                    localStorage.removeItem("userInfo");
                    localStorage.removeItem("selectedPremiseID");
                    localStorage.removeItem("selectedUserID");
                    localStorage.removeItem("selectedUserEmail");
                    localStorage.removeItem("Authorization");
                    localStorage.removeItem("permissions");
                    window.location.reload();
                } else {
                    // Handle other errors as needed
                    console.error("Error fetching data:", err);
                }
            }
            );
    }

    console.log("datadatadata", tableData);
    // var expandedRowsState: any = {};
    console.log("expandedRowsState~~~~~~", expandedRowsState);

    // const restoreRowClasses = (expandedRows: any) => {
    //     console.log("Restoring row classes...");
    //     console.log("expandedRowsState ==", expandedRows);

    //     // Ensure the table is properly initialized
    //     if (!$.fn.DataTable.isDataTable(tableRef.current)) {
    //         console.error('DataTable is not initialized.');
    //         return;
    //     }

    //     // Iterate over expanded rows and apply classes
    //     setTimeout(() => {
    //         // Iterate over expanded rows and apply classes
    //         Object.keys(expandedRows).forEach(rowId => {
    //             console.log("Restoring row for ID:", rowId);
    
    //             const $row = $(tableRef.current).find(`tr[data-id="${rowId}"]`);
    //             if ($row.length) {
    //                 console.log("Selected row:", $row);
    
    //                 let $detailsRow = $row.next('.details-row');
    //                 const rowData = $(tableRef.current).DataTable().data();
    //                 console.log("rowDatarowData", rowData);
    //                 if ($detailsRow.length === 0) {
    //                     console.log('Details row not found for ID:', rowId);
                        
    //                     const $detailsTable = $('<table>').addClass('table table-hover').css({
    //                         width: '100%',
    //                         fontSize: '14px',
    //                         color: '#858D9D',
    //                     });

    //                     const $thead = $('<thead>');
    //                 const $headerRow = $('<tr>');
    //                 $headerRow.append($('<th>').text('Display Name'));
    //                 $headerRow.append($('<th>').text('Button'));
    //                 $headerRow.append($('<th>').text('On/Off'));
    //                 $headerRow.append($('<th>').text('Dimmer Value'));
    //                 $headerRow.css('background-color', '#EFF2F7');
    //                 $thead.append($headerRow);

    //                 const $tbody = $('<tbody>');
    //                 // Populate $tbody with your data as needed
    //                 // Example: Adding a dummy row
    //                 const $bodyRow = $('<tr>');
    //                 $bodyRow.append($('<td>').text('Example Display Name'));
    //                 $bodyRow.append($('<td>').text('Example Button'));
    //                 $bodyRow.append($('<td>').text('Example On/Off'));
    //                 $bodyRow.append($('<td>').text('Example Dimmer Value'));
    //                 $tbody.append($bodyRow);
                    
    //                 $detailsTable.append($thead);
    //                 $detailsTable.append($tbody);
    //                 $detailsRow.empty();
    //                     // Create the details row if it's missing
    //                     const $detailsCell = $('<td>').attr('colspan', columns.length).append($detailsTable);
    //                     const $newRow = $('<tr>').addClass('details-row').append($detailsCell).addClass('open-row-table');
    //                     $row.after($newRow);
    //                 }
    
    //                 // $detailsRow.addClass('open-row-table');
    //                 // console.log("After adding class:", $detailsRow.attr('class'));
    
    //                 // const $toggleIcon = $row.find('.details-row-toggle i');
    //                 // if ($toggleIcon.length) {
    //                 //     $toggleIcon.removeClass('fa-caret-right').addClass('fa-caret-down');
    //                 // }
    //             } else {
    //                 console.error('Row not found for ID:', rowId);
    //             }
    //         });
    //     }, 100); 
    // };


    const restoreRowClasses = (expandedRows: any,eapandedBlasterRow:any) => {
        setDataReDraw(false)
        console.log("Restoring row classes...");
        console.log("expandedRowsState ==", expandedRows);

        // Ensure the table is properly initialized
        if (!$.fn.DataTable.isDataTable(tableRef.current)) {
            console.error('DataTable is not initialized.');
            return;
        }

        // Iterate over expanded rows and apply classes
        Object.keys(expandedRows).forEach(rowId => {
            console.log("dataTableInstance in restore", tableRef.current);

            // const $row = $(tableRef.current).find(`tr[data-id="${rowId}"]`);
            const tableInstance = $(tableRef.current).DataTable();
            const rowSelector = `tr[data-id="${rowId}"]`;
            const $row = $(tableRef.current).find(rowSelector);
            console.log("Selected row:", $row);

            if ($row.length) {
                const $detailsRow = $row.next('.details-row');
                console.log("Before adding class:", dataTableInstance);
                // if ($row) {
                    const rowIndex = tableInstance.row($row).index();
                    // const rowData = $(tableRef.current).DataTable().data();
                    const rowData = tableInstance.row(rowIndex).data();
                    console.log("rowDatarowData", rowData);
                // }
                const $icon = $row.find('.details-row-toggle i');
                if ($detailsRow.length === 0) {
                    console.log('Details row not found for ID:', rowId);
                    $icon.removeClass('bx bx-caret-right').addClass('bx bx-caret-down');
                    const detailsTable = $('<table>').addClass('table table-hover').css({
                        width: '100%',
                        fontSize: '14px',
                        color: '#858D9D',
                    });

                    if (rowData.switches && Array.isArray(rowData.switches) || rowData && rowData.remotes && Array.isArray(rowData.remotes)) {
                        const $thead = $('<thead>');
                        const $headerRow = $('<tr>');
                        // Add headers dynamically
                        if (idName === "Chipslist") {
                            $headerRow.append($('<th>').text('Display Name'));
                            $headerRow.append($('<th>').text('Button'));
                            $headerRow.append($('<th>').text('On/Off'));
                            $headerRow.append($('<th>').text('Dimmer Value'));
                        } else if (idName === "Blasterlist") {
                            $headerRow.append($('<th>').text(''));
                            $headerRow.append($('<th>').text('RemoteName'));
                            $headerRow.append($('<th>').text('RoomName'));
                            $headerRow.append($('<th>').text('Image'));
                            $headerRow.append($('<th>').text('Type'));
                            $headerRow.append($('<th>').text('Brand Name'));
                            $headerRow.append($('<th>').text('Brand Model'));
                        }
                        $headerRow.css('background-color', '#EFF2F7');
                        $thead.append($headerRow);

                        const $tbody = $('<tbody>');
                        if (idName === "Chipslist") {
                            rowData.switches.forEach((switchItem: any) => {
                                const $row = $('<tr>');
                                $row.append($('<td>').text(switchItem.displayName));
                                $row.append($('<td>').text(switchItem.button));
                                $row.append($('<td>').text(switchItem.isOn));
                                $row.append($('<td>').text(switchItem.dimmerValue));
                                $tbody.append($row);
                            });
                        } else if (idName === "Blasterlist") {
                            rowData.remotes.forEach((remoteItem: any) => {
                                const $row = $('<tr>');
                                $row.attr('data-id', remoteItem.id);
                                const $toggleIcon = $('<div>').addClass('details-row-toggle1').data('remoteData', remoteItem).html('<i class="bx bx-caret-right" style="cursor: pointer";></i>');
                                $row.append($('<td>').append($toggleIcon));
                                $row.append($('<td>').text(remoteItem.name));
                                $row.append($('<td>').text(remoteItem.roomId.name));
                                $row.append($('<td>').text(remoteItem.image));
                                $row.append($('<td>').text(remoteItem.type));
                                $row.append($('<td>').text(remoteItem.brandName));
                                $row.append($('<td>').text(remoteItem.brandModal));
                                $tbody.append($row);
                                restoreBlasterTable(remoteItem,eapandedBlasterRow,$row,rowData?.id)
                            });
                        }
                        detailsTable.append($thead);
                        detailsTable.append($tbody);
                    }
                $detailsRow.empty();
                    // Create the details row if it's missing
                    const $detailsCell = $('<td>').attr('colspan', columns.length).append(detailsTable);
                    const $newRow = $('<tr>').addClass('details-row').append($detailsCell).addClass('open-row-table');
                    $row.after($newRow);
                }
            } else {
                console.error('Row not found for ID:', rowId);
            }
        });
    };

    const restoreBlasterTable = (data:any,eapandedBlasterRow:any,row:any,id:any) => {
        if (!$.fn.DataTable.isDataTable(tableRef.current)) {
            console.error('DataTable is not initialized.');
            return;
        }
        console.log("data from blaster table", eapandedBlasterRow);
        
        Object.keys(eapandedBlasterRow).forEach(rowId => {
            const tableInstance = $(tableRef.current).DataTable();
            console.log("tableInstance",tableInstance);
            
            // const rowSelector = `tr[data-id="${rowId}"]`;
            // const $row = $(tableRef.current).find(rowSelector);
            // console.log("find second row", $row.find(rowId));
            
            const blasterRowSelected = `tr[data-id="${rowId}"]`;
            console.log("row(blasterRowSelected).find().data().id",data.id, rowId );
            if(data.id !== rowId ){
                return;
            } else {
            // const $mainrow = $($row).find(blasterRowSelected);
            // console.log("Selected row for blaster:", $mainrow);
            const $remotedetailsRow = row.next('.details-remote-row').find(blasterRowSelected);
            const $icon = row.find('.details-row-toggle1 i');
            const detailsTableVisible = $remotedetailsRow.find('table').length > 0;
            console.log("detailsTableVisible", detailsTableVisible);

            const remoteDetailsTable = $('<table>').addClass('table table-hover').css({
                width: '100%',
                fontSize: '14px',
                color: '#858D9D',
            });
            if (data && ((data.switches && Array.isArray(data.switches)) || (data.remotes && Array.isArray(data.remotes)))) {
                console.log("call if remote in blaster");
                
                $icon.removeClass('bx bx-caret-right').addClass('bx bx-caret-down');
                const $thead = $('<thead>');
                const $headerRow = $('<tr>');
                $headerRow.append($('<th>').text('Key ID'));
                $headerRow.append($('<th>').text('Key Name'));
                $headerRow.css('background-color', '#EFF2F7');
                $thead.append($headerRow);

                const $tbody = $('<tbody>');

                if (data.switches && data.switches.length > 0) {
                    data.switches.forEach((switchItem: any) => {
                        const $row = $('<tr>');
                        $row.append($('<td>').text(switchItem.switchKeyId));
                        $row.append($('<td>').text(switchItem.switchKeyName));
                        $tbody.append($row);
                    });
                } else {
                    const $row = $('<tr>');
                    $row.append($('<td>').text(""));
                    $row.append($('<td>').text("Switches Not Available").attr('colspan', 2));
                    $tbody.append($row);
                }

                remoteDetailsTable.append($thead);
                remoteDetailsTable.append($tbody);
            }
            $remotedetailsRow.empty();
            const $detailsCell = $('<td>').attr('colspan', row.find('td').length).append(remoteDetailsTable);
            const $newRow = $('<tr>').addClass('details-remote-row').append($detailsCell);
            // remoteDetailsTable.show();
            $remotedetailsRow.addClass('open-remote-row-table');
            row.after($newRow);
        }
        });
    }
    
    useEffect(() => {
        console.log("expandedRowsState in useEffect", expandedRowsState,dataReDraw);
        
        if (dataReDraw) {
            // setTimeout(() => {
                console.log("Restoring row classes...");
                console.log("Expanded Rows State:", expandedRowsState);
                restoreRowClasses(expandedRowsState,expandedBlasterState);
            // }, 1000); // Shortened timeout for testing
        }
    }, [dataReDraw, expandedRowsState,expandedBlasterState]);
    
    const initializeData = () => {
        try {
            if ($.fn.DataTable.isDataTable(tableRef.current)) {
                // saveRowClasses();
                $(tableRef.current).DataTable().destroy();
            }

            dataTableInstance = $(tableRef.current).DataTable({
                processing: false,
                serverSide: true,
                ajax: fetchData,
                drawCallback: function () {
                    console.log("Draw callback triggered",dataReDraw);
                    console.log("Setting dataReDraw to true");
                    setDataReDraw(true);
                },
                rowCallback: function (row: any, data: any) {
                    $(row).attr('data-id', data.id); // Set the data-id attribute
                },
                columns: columns,
                searching: true,
                ordering: false,
            });
        } catch (error) {
            console.error('Error initializing DataTable:', error);
        }

        $(tableRef.current).on('click', '.column-click', function () {
            const data = dataTableInstance.row($(this).closest('tr')).data();
            if (!isClicked) {
                return;
            }
            handleColumnClicked && handleColumnClicked(data)
        });


        $(tableRef.current).on('click', '.delete-btn', function () {
            const rowData = dataTableInstance.row($(this).parents('tr')).data();
            console.log("rowDatarowData", dataTableInstance.row($(this).parents('tr')).data())
            handleDeleteClick && handleDeleteClick(rowData);
        });

        $(tableRef.current).on('click', '.log-btn', function () {
            const rowData = dataTableInstance.row($(this).parents('tr')).data();
            console.log("rowData", dataTableInstance.row($(this).parents('tr')).data())

            handleEditClick && handleEditClick(rowData);
        });

        // $(tableRef.current).on('click', '.details-row-toggle', function () {
        //     handleRowToggle(this);
        // });
        $(tableRef.current).on('click', '.details-row-toggle', function () {
            console.log("Clicked details-row-toggle");

            const $row = $(this).closest('tr');
            const $detailsRow = $row.next('.details-row');
            const rowData = dataTableInstance.row($row).data();
            console.log("rowData~~~~~~~>",rowData);

            if (!rowData) {
                console.error('No data found for the row:', $row);
                return;
            }

            const rowId = rowData.id; // Assuming each row has a unique `id` field
            const $icon = $(this).find('i');

            // Check if the details row is already expanded
            setExpandedRowsState(prevState => {
                const newState: any = { ...prevState };
                if ($detailsRow.hasClass('open-row-table')) {
                    console.log("Closing row");
                    $icon.removeClass('bx bx-caret-down').addClass('bx bx-caret-right');
                    $detailsRow.removeClass('open-row-table');
                    $detailsRow.empty();
                    delete newState[rowId];
                    // delete expandedRowsState[rowId]; // Remove from state
                } else {
                    console.log("Opening row");
                    $icon.removeClass('bx bx-caret-right').addClass('bx bx-caret-down');

                    const detailsTable = $('<table>').addClass('table table-hover').css({
                        width: '100%',
                        fontSize: '14px',
                        color: '#858D9D',
                    });
                    // Populate detailsTable based on rowData
                    if (rowData.switches && Array.isArray(rowData.switches) || rowData && rowData.remotes && Array.isArray(rowData.remotes)) {
                        const $thead = $('<thead>');
                        const $headerRow = $('<tr>');
                        // Add headers dynamically
                        if (idName === "Chipslist") {
                            $headerRow.append($('<th>').text('Display Name'));
                            $headerRow.append($('<th>').text('Button'));
                            $headerRow.append($('<th>').text('On/Off'));
                            $headerRow.append($('<th>').text('Dimmer Value'));
                        } else if (idName === "Blasterlist") {
                            $headerRow.append($('<th>').text(''));
                            $headerRow.append($('<th>').text('RemoteName'));
                            $headerRow.append($('<th>').text('RoomName'));
                            $headerRow.append($('<th>').text('Image'));
                            $headerRow.append($('<th>').text('Type'));
                            $headerRow.append($('<th>').text('Brand Name'));
                            $headerRow.append($('<th>').text('Brand Model'));
                        }
                        $headerRow.css('background-color', '#EFF2F7');
                        $thead.append($headerRow);

                        const $tbody = $('<tbody>');
                        if (idName === "Chipslist") {
                            rowData.switches.forEach((switchItem: any) => {
                                const $row = $('<tr>');
                                $row.append($('<td>').text(switchItem.displayName));
                                $row.append($('<td>').text(switchItem.button));
                                $row.append($('<td>').text(switchItem.isOn));
                                $row.append($('<td>').text(switchItem.dimmerValue));
                                $tbody.append($row);
                            });
                        } else if (idName === "Blasterlist") {
                            rowData.remotes.forEach((remoteItem: any) => {
                                const $row = $('<tr>');
                                $row.attr('data-id', remoteItem.id);
                                const $toggleIcon = $('<div>').addClass('details-row-toggle1').data('remoteData', remoteItem).html('<i class="bx bx-caret-right" style="cursor: pointer";></i>');
                                $row.append($('<td>').append($toggleIcon));
                                $row.append($('<td>').text(remoteItem.name));
                                $row.append($('<td>').text(remoteItem.roomId.name));
                                $row.append($('<td>').text(remoteItem.image));
                                $row.append($('<td>').text(remoteItem.type));
                                $row.append($('<td>').text(remoteItem.brandName));
                                $row.append($('<td>').text(remoteItem.brandModal));
                                $tbody.append($row);
                            });
                        }
                        detailsTable.append($thead);
                        detailsTable.append($tbody);
                    }
                    newState[rowId] = true;
                    $detailsRow.empty();
                    const $detailsCell = $('<td>').attr('colspan', columns.length).append(detailsTable);
                    const $newRow = $('<tr>').addClass('details-row').append($detailsCell).addClass('open-row-table');
                    $row.after($newRow);

                    // Save the expanded state
                    // expandedRowsState[rowId] = true;
                    console.log("expandedRowsState", expandedRowsState);

                }
                return newState;
            });
            // Optionally: Save the state to localStorage or server here
        });
        $(tableRef.current).off('click', '.details-row-toggle1').on('click', '.details-row-toggle1', function () {
            const $row = $(this).closest('tr');
            const $remotedetailsRow = $row.next('.details-remote-row');
            const $icon = $(this).find('i');

            console.log("Clicked row:", $row);
            console.log("Details row:", $remotedetailsRow);
            console.log("Is open:", $remotedetailsRow.hasClass('open-remote-row-table'));
            const detailsTableVisible = $remotedetailsRow.find('table').length > 0;
            console.log("detailsTableVisible", detailsTableVisible);
            setExpandedBlasterState(prevState => {
                const newState: any = { ...prevState };
                
                const data = $(this).data('remoteData');

                if (!data) {
                    console.error('No data found for the row:', $row);
                    return;
                }
    
            if (detailsTableVisible) {
                // If already open, close it
                console.log("render if condition");
                $icon.removeClass('bx bx-caret-down').addClass('bx bx-caret-right');
                $remotedetailsRow.removeClass('open-remote-row-table');
                $remotedetailsRow.empty();
                delete newState[data.id];
                // return;
            } else {

            const remoteDetailsTable = $('<table>').addClass('table table-hover').css({
                width: '100%',
                fontSize: '14px',
                color: '#858D9D',
            });

            if (data && ((data.switches && Array.isArray(data.switches)) || (data.remotes && Array.isArray(data.remotes)))) {
                console.log("call if remote");
                
                $icon.removeClass('bx bx-caret-right').addClass('bx bx-caret-down');
                const $thead = $('<thead>');
                const $headerRow = $('<tr>');
                $headerRow.append($('<th>').text('Key ID'));
                $headerRow.append($('<th>').text('Key Name'));
                $headerRow.css('background-color', '#EFF2F7');
                $thead.append($headerRow);

                const $tbody = $('<tbody>');

                if (data.switches && data.switches.length > 0) {
                    data.switches.forEach((switchItem: any) => {
                        const $row = $('<tr>');
                        $row.append($('<td>').text(switchItem.switchKeyId));
                        $row.append($('<td>').text(switchItem.switchKeyName));
                        $tbody.append($row);
                    });
                } else {
                    const $row = $('<tr>');
                    $row.append($('<td>').text(""));
                    $row.append($('<td>').text("Switches Not Available").attr('colspan', 2));
                    $tbody.append($row);
                }

                remoteDetailsTable.append($thead);
                remoteDetailsTable.append($tbody);
            }
            newState[data.id] = true;
            $remotedetailsRow.empty();
            const $detailsCell = $('<td>').attr('colspan', $row.find('td').length).append(remoteDetailsTable);
            const $newRow = $('<tr>').addClass('details-remote-row').append($detailsCell);
            // remoteDetailsTable.show();
            $remotedetailsRow.addClass('open-remote-row-table');
            $row.after($newRow);
        }
            return newState;
        });
        });

        $(tableRef.current).on('preXhr.dt', function () {
            $('#customLoader').show();
        });

        $(tableRef.current).on('xhr.dt', function () {
            $('#customLoader').hide();
        });
        $(tableRef.current).find('thead th').css('background-color', '#EFF2F7');

        console.log("check loader", $('.dataTables_processing'));
        return () => {
            // Clean up DataTable instance on unmounting or before re-rendering
            $(tableRef.current).DataTable().destroy();
        };
    }

    useEffect(() => {
        // if (!loading && tableData.length > 0) {
        if (idName === "Chipslist" || idName === "Blasterlist") {
            // if (selectedRooms && selectedRooms.length > 0) {
            initializeData()
            // }
        }
    }, [selectedRooms]);

    useEffect(() => {
        if (idName !== "Chipslist" && idName !== "Blasterlist") {
            initializeData();
        }
    }, [loading]);

    useEffect(() => {
        // reloadDataTable();
    }, [selectedRooms]);

    const reloadDataTable = () => {
        const dataTableId = $(tableRef.current).attr('id');
        console.log("dataTableIddataTableId", dataTableId);
        if (dataTableId && dataTableId === "dataTableChipslist") {
            console.log("render if condition in reload table");
            const dataTableInstance: any = $(`#${dataTableId}`).DataTable();
            console.log("dataTableInstance", dataTableInstance);
            // if (selectedRooms && selectedRooms.length > 0) {
            dataTableInstance.ajax.reload(null, false);
            // }
        }
    }

    useEffect(() => {
        const dataTableId = $(tableRef.current).attr('id');
        onDataTableRefresh && onDataTableRefresh(dataTableId);
    }, [])

    // useEffect(() => {
    //     if (dataTableInstance) {
    //         console.log("go to data table");

    //         // Open rows based on expandedRows array after DataTable is initialized

    //     }
    // }, [expandedRows]);

    //  console.log("dataTableInstancedataTableInstance", dataTableInstance);
    return (
        <Card>
            <CardBody>
                <div className="mb-4 h4 card-title d-flex justify-content-between flex-row" >
                    <h5 style={{ color: '#000', fontWeight: 'bold', fontSize: 17 }}>{title}</h5>
                    <div className="justify-content-between flex-row d-flex" style={{ gap: 20 }}>
                        {isAddBtnShow &&
                            <button className={btnStyle}
                                onClick={() => onClickBtn && onClickBtn()}
                            >
                                {btnName}
                            </button>}
                        {isBtnShow &&
                            <button
                                className={secondBtnStyle}
                                style={{ gap: 20 }}
                                onClick={() => secondBtnOnClick && secondBtnOnClick()}
                            >
                                {secondBtnText}
                            </button>}
                    </div>
                </div>
                <div>

                    {/* <Spinners /> */}
                    <div id="customLoader" style={{
                        display: 'none',
                        position: 'fixed',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        zIndex: 9999,
                        backgroundColor: 'rgba(255, 255, 255, 0.8)',
                        padding: '20px',
                    }}>
                        <p>Processing....</p>
                    </div>
                    {initializeTable === 0 && (
                        initializeTable = 1,
                        <div className='table-responsive'>
                            <table
                                id={`dataTable${idName}`}
                                ref={tableRef}
                                className="table table-hover"
                                style={{ width: '100%', fontSize: 14, color: "#858D9D" }}
                            >
                            </table>
                        </div>)}
                </div>
            </CardBody>
        </Card >
    )
}

export default ChipDataTable


// import React, { useEffect, useRef, useState } from 'react'
// import axios from 'axios';
// import 'datatables.net';
// import 'datatables.net-bs4';
// import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
// import $ from 'jquery'
// import { Card, CardBody } from 'reactstrap';

// import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
// import { PremisesListData, PremisesListResponse } from '../../../../helpers/response/Admin/PremiseListResponse';
// import getStorageData from '../../../../Utils/getStorageData';
// import { useDispatch } from 'react-redux';
// import { chipLogs } from '../../../../store/slices/APIResponseSlice';
// import { useSelector } from 'react-redux';

// export interface interfaceDataTable<T> {
//     responseType?: T;
//     apiURL: string,
//     columns: any[],
//     btnName?: string,
//     title?: string,
//     isAddBtnShow?: boolean,
//     btnStyle?: any,
//     isBtnShow?: boolean,
//     secondBtnText?: string,
//     secondBtnStyle?: any,
//     onClickBtn?: () => void,
//     secondBtnOnClick?: () => void,
//     handleEditClick?: (data: any) => void,
//     handleDeleteClick?: (data: any) => void,
//     onDataTableRefresh?: (dataTableId: any) => void,
//     idName: string,
//     handleColumnClicked?: (data: any) => void | undefined,
//     selectedRoomID?: any[],
//     paramsId?: any,
//     isEntryShow?: boolean,
//     isClicked?: boolean,
//     setReload?: any,
//     onRowToggle?: (rowId: any) => any
//     expandedRows?: any[];
// }
// const ProfileListDataTable = <T,>({
//     responseType,
//     title,
//     apiURL,
//     columns,
//     btnName,
//     isAddBtnShow,
//     btnStyle,
//     isBtnShow,
//     secondBtnText,
//     secondBtnStyle,
//     onClickBtn,
//     secondBtnOnClick,
//     handleEditClick,
//     handleDeleteClick,
//     onDataTableRefresh,
//     idName,
//     handleColumnClicked,
//     selectedRoomID,
//     paramsId,
//     isEntryShow,
//     isClicked,
//     setReload,
//     onRowToggle,
//     expandedRows
// }: interfaceDataTable<T>) => {
//     const selectedRooms = useSelector((state: any) => state.APIResponse.selectedRooms);
//     console.log("selectedRoomssssss", selectedRoomID);

//     // const [data, setData] = useState([]);
//     const dispatch = useDispatch();
//     const [tableData, setTableData] = useState<PremisesListData[]>([]);
//     const [loading, setLoading] = useState(false);
//     const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
//     const [currentPage, setCurrentPage] = useState(0);
//     console.log("datadata", isClicked);
//     const tableRef: any = useRef(null);
//     const userType = getStorageData().userType;


//     const token = localStorage.getItem("Authorization");

//     var initializeTable = 0;

//     // console.log("tableRef", tableRef);


//     let dataTableInstance: any = null;
//     const fetchData = (dataTablesData: any, callback: any) => {
//         if (idName === "Chipslist" || idName === "Blasterlist") {
//             setReload(false);
//         }
//         // setLoading(true)
//         $('#customLoader').show()
//         console.log("dataTablesData", selectedRoomID);
//         const reqObj: any = {
//             ...dataTablesData,
//             ...paramsId,
//             roomId: selectedRoomID && selectedRoomID
//                 .filter((item: any) => item.isChecked)
//                 .map((item: any) => item.id)
//                 .join(",")
//         }
//         axios.post(apiURL && apiURL, reqObj, {
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             }
//         }).then((response) => {
//             const res = response.data
//             console.log("res", res);
//             dispatch(chipLogs({
//                 totalOfflineTime: res?.totalOfflineTime,
//                 totalOnlineTime: res?.totalOnlineTime
//             }))
//             $('#customLoader').hide();
//             const tableInstance = $(tableRef.current).DataTable();

//             // Update the specific cell in each row
            


//             callback({
//                 draw: dataTablesData.draw,
//                 recordsTotal: res.recordsTotal,
//                 recordsFiltered: res.recordsFiltered, // You might adjust this based on filtered records
//                 data: res.data,
//             });
//             if (idName === "Chipslist" || idName === "Blasterlist") {
//                 if (dataTablesData?.start >= 10) {
//                     setReload(false);
//                     // openExpandedRows();
//                 } else {
//                     setReload(true);
//                     // openExpandedRows();
//                 }
//             }
//             setTableData(res.data)
//         })
//             .catch((err) => {
//                 if (err.response && err.response.status === 401) {
//                     // window.localStorage.clear();
//                     localStorage.removeItem("userInfo");
//                     localStorage.removeItem("selectedPremiseID");
//                     localStorage.removeItem("selectedUserID");
//                     localStorage.removeItem("selectedUserEmail");
//                     localStorage.removeItem("Authorization");
//                     localStorage.removeItem("permissions");
//                     window.location.reload();
//                 } else {
//                     // Handle other errors as needed
//                     console.error("Error fetching data:", err);
//                 }
//             }
//             );
//     }

//     console.log("datadatadata", tableData);
//     const rowStates: any = {};
//     const initializeData = () => {
//         try {
//             if ($.fn.DataTable.isDataTable(tableRef.current)) {
//                 $(tableRef.current).DataTable().destroy();
//             }

//             dataTableInstance = $(tableRef.current).DataTable({
//                 processing: false,
//                 serverSide: true,
//                 ajax: fetchData,
//                 drawCallback: function (json: any) {
//                     // Handle your API response here
//                     processDetailsRowToggle();
//                     return json.data; // Return the data to DataTable
//                 },
//                 columns: columns,
//                 searching: true,
//                 ordering: false,
//             });
//         } catch (error) {
//             console.error('Error initializing DataTable:', error);
//         }

//         $(tableRef.current).on('click', '.column-click', function () {
//             const data = dataTableInstance.row($(this).closest('tr')).data();
//             if (!isClicked) {
//                 return;
//             }
//             handleColumnClicked && handleColumnClicked(data)
//         });


//         $(tableRef.current).on('click', '.delete-btn', function () {
//             const rowData = dataTableInstance.row($(this).parents('tr')).data();
//             console.log("rowDatarowData", dataTableInstance.row($(this).parents('tr')).data())
//             handleDeleteClick && handleDeleteClick(rowData);
//         });

//         $(tableRef.current).on('click', '.log-btn', function () {
//             const rowData = dataTableInstance.row($(this).parents('tr')).data();
//             console.log("rowData", dataTableInstance.row($(this).parents('tr')).data())

//             handleEditClick && handleEditClick(rowData);
//         });

//         $(tableRef.current).on('click', '.details-row-toggle', function () {
//             console.log("call details row toggle");

//             const $row = $(this).closest('tr');

//             const $detailsRow = $row.next('.details-row');
//             console.log('After adding class:', $detailsRow);
//             // $detailsRow.toggle();
//             // $detailsRow.addClass('open');
//             console.log('Before adding class:', $detailsRow.hasClass('open-row-table'));
//             const rowData = dataTableInstance.row($row).data();
//             if (!rowData) {
//                 // console.error('No data found for the row:', $row);
//                 return;
//             }

//             const rowId: number = rowData.id; // Again, assuming each row has a unique `id` field
//             onRowToggle && onRowToggle(rowId)
//             // Toggle the state
//             rowStates[rowId] = !rowStates[rowId];
//             const $icon = $(this).find('i');

//             // rowData.detailsOpen = true;
//             if ($detailsRow.hasClass('open-row-table')) {
//                 // If already open, close it
//                 console.log("render if condition");
//                 $icon.removeClass('fa-caret-down').addClass('fa-caret-right');
//                 $detailsRow.removeClass('open-row-table');
//                 $detailsRow.empty();
//                 return;
//             }

//             const detailsTable = $('<table>').addClass('table table-hover').css({
//                 width: '100%',
//                 fontSize: '14px',
//                 color: '#858D9D',
//             });
//             // Assuming the icon is inside the details-row-toggle element
//             console.log("icon", $icon);

//             if (rowData && rowData.switches && Array.isArray(rowData.switches) || rowData && rowData.remotes && Array.isArray(rowData.remotes) || expandedRows && expandedRows.includes(rowData?.id)
//             ) {
//                 $icon.removeClass('fa-caret-right').addClass('fa-caret-down');
//                 const $thead = $('<thead>');
//                 const $headerRow = $('<tr>');
//                 if (idName === "Chipslist") {
//                     $headerRow.append($('<th>').text('Display Name'));
//                     $headerRow.append($('<th>').text('Button'));
//                     $headerRow.append($('<th>').text('On/Off'));
//                     $headerRow.append($('<th>').text('Dimmer Value'));
//                 } else if (idName === "Blasterlist") {
//                     $headerRow.append($('<th>').text(''));
//                     $headerRow.append($('<th>').text('RemoteName'));
//                     $headerRow.append($('<th>').text('RoomName'));
//                     $headerRow.append($('<th>').text('Image'));
//                     $headerRow.append($('<th>').text('Type'));
//                     $headerRow.append($('<th>').text('Brand Name'));
//                     $headerRow.append($('<th>').text('Brand Model'));
//                     // $headerRow.append($('<th>').text('Actions'));
//                 }
//                 $headerRow.css('background-color', '#EFF2F7');
//                 $thead.append($headerRow)

//                 const $tbody = $('<tbody>');
//                 if (idName === "Chipslist") {
//                     rowData.switches.forEach((switchItem: any) => {
//                         const $row = $('<tr>');
//                         $row.append($('<td>').text(switchItem.displayName));
//                         $row.append($('<td>').text(switchItem.button));
//                         $row.append($('<td>').text(switchItem.isOn));
//                         $row.append($('<td>').text(switchItem.dimmerValue));
//                         $tbody.append($row);
//                     });
//                 } else if (idName === "Blasterlist") {
//                     rowData.remotes.forEach((remoteItem: any) => {
//                         const $row = $('<tr>');
//                         const $toggleIcon = $('<div>').addClass('details-row-toggle1').data('remoteData', remoteItem).html('<i class="fas fa-caret-right" style="cursor: pointer";></i>');
//                         $row.append($('<td>').append($toggleIcon));
//                         $row.append($('<td>').text(remoteItem.name));
//                         $row.append($('<td>').text(remoteItem.roomId.name));
//                         $row.append($('<td>').text(remoteItem.image));
//                         $row.append($('<td>').text(remoteItem.type));
//                         $row.append($('<td>').text(remoteItem.brandName));
//                         $row.append($('<td>').text(remoteItem.brandModal));
//                         // $row.append($('<td>').html('<i class="bx bx-trash-alt" style="font-size: 20px;"></i>'));
//                         $tbody.append($row);

//                     });
//                 }
//                 detailsTable.append($thead);
//                 detailsTable.append($tbody);
//                 $(this).find('.details-row-toggle1').data('rowData', rowData);
//             }

//             $detailsRow.empty();
//             console.log('after adding class:', $detailsRow.hasClass('open-row-table'));
//             const $detailsCell = $('<td>').attr('colspan', columns.length).append(detailsTable);
//             // $(this).find('.details-row-toggle1').data('rowData', rowData);
//             const $newRow = $('<tr>').addClass('details-row').append($detailsCell).addClass('open-row-table');
//             $row.after($newRow);
//         });

//         $(tableRef.current).off('click', '.details-row-toggle1').on('click', '.details-row-toggle1', function () {
//             const $row = $(this).closest('tr');
//             const $remotedetailsRow = $row.next('.details-remote-row');
//             const $icon = $(this).find('i');

//             console.log("Clicked row:", $row);
//             console.log("Details row:", $remotedetailsRow);
//             console.log("Is open:", $remotedetailsRow.hasClass('open-remote-row-table'));
//             const detailsTableVisible = $remotedetailsRow.find('table').length > 0;
//             console.log("detailsTableVisible", detailsTableVisible);

//             if (detailsTableVisible) {
//                 // If already open, close it
//                 console.log("render if condition");
//                 $icon.removeClass('fa-caret-down').addClass('fa-caret-right');
//                 $remotedetailsRow.removeClass('open-remote-row-table');
//                 $remotedetailsRow.empty();
//                 return;
//             }

//             const data = $(this).data('remoteData');

//             if (!data) {
//                 console.error('No data found for the row:', $row);
//                 return;
//             }

//             const remoteDetailsTable = $('<table>').addClass('table table-hover').css({
//                 width: '100%',
//                 fontSize: '14px',
//                 color: '#858D9D',
//             });

//             if (data && ((data.switches && Array.isArray(data.switches)) || (data.remotes && Array.isArray(data.remotes)))) {
//                 $icon.removeClass('fa-caret-right').addClass('fa-caret-down');
//                 const $thead = $('<thead>');
//                 const $headerRow = $('<tr>');
//                 $headerRow.append($('<th>').text('Key ID'));
//                 $headerRow.append($('<th>').text('Key Name'));
//                 $headerRow.css('background-color', '#EFF2F7');
//                 $thead.append($headerRow);

//                 const $tbody = $('<tbody>');

//                 if (data.switches && data.switches.length > 0) {
//                     data.switches.forEach((switchItem: any) => {
//                         const $row = $('<tr>');
//                         $row.append($('<td>').text(switchItem.switchKeyId));
//                         $row.append($('<td>').text(switchItem.switchKeyName));
//                         $tbody.append($row);
//                     });
//                 } else {
//                     const $row = $('<tr>');
//                     $row.append($('<td>').text(""));
//                     $row.append($('<td>').text("Switches Not Available").attr('colspan', 2));
//                     $tbody.append($row);
//                 }

//                 remoteDetailsTable.append($thead);
//                 remoteDetailsTable.append($tbody);
//             }

//             $remotedetailsRow.empty();
//             const $detailsCell = $('<td>').attr('colspan', $row.find('td').length).append(remoteDetailsTable);
//             const $newRow = $('<tr>').addClass('details-remote-row').append($detailsCell);
//             // remoteDetailsTable.show();
//             $remotedetailsRow.addClass('open-remote-row-table');
//             $row.after($newRow);
//         });


//         $(tableRef.current).on('preXhr.dt', function () {
//             $('#customLoader').show();
//         });

//         $(tableRef.current).on('xhr.dt', function () {
//             $('#customLoader').hide();
//         });
//         $(tableRef.current).find('thead th').css('background-color', '#EFF2F7');

//         console.log("check loader", $('.dataTables_processing'));
//         return () => {
//             // Clean up DataTable instance on unmounting or before re-rendering
//             $(tableRef.current).DataTable().destroy();
//         };
//     }

//     const processDetailsRowToggle = () => {
//         // Access elements with the class details-row-toggle
//         $(tableRef.current).find('.details-row-toggle').each(function () {
//             const $row = $(this).closest('tr');
//             const rowData = dataTableInstance.row($row).data();
//             console.log("rowData", rowData);

//             // Check if the row is already expanded
//             if ($row.next().hasClass('details-row')) {
//                 // Remove the expanded row
//                 $row.next().remove();
//             } else {
//                 // Render and insert the details table
//                 const detailsTable = renderDetailsTable(rowData, idName);
//                 if (detailsTable) {
//                     const $detailsRow = $('<tr class="details-row">').append(
//                         $('<td>').attr('colspan', columns.length).append(detailsTable)
//                     );
//                     $row.after($detailsRow);
//                 }
//             }
//         });
//     };

//     const renderDetailsTable = (rowData: any, idName: any) => {
//         if (expandedRows && expandedRows?.includes(rowData.id)) {

//             const detailsTable = $('<table>').addClass('table table-hover').css({
//                 width: '100%',
//                 fontSize: '14px',
//                 color: '#858D9D',
//             });
//             console.log("detailsTable", detailsTable);

//             const $thead = $('<thead>');
//             const $headerRow = $('<tr>');
//             if (idName === "Chipslist") {
//                 $headerRow.append($('<th>').text('Display Name'));
//                 $headerRow.append($('<th>').text('Button'));
//                 $headerRow.append($('<th>').text('On/Off'));
//                 $headerRow.append($('<th>').text('Dimmer Value'));
//             } else if (idName === "Blasterlist") {
//                 $headerRow.append($('<th>').text(''));
//                 $headerRow.append($('<th>').text('RemoteName'));
//                 $headerRow.append($('<th>').text('RoomName'));
//                 $headerRow.append($('<th>').text('Image'));
//                 $headerRow.append($('<th>').text('Type'));
//                 $headerRow.append($('<th>').text('Brand Name'));
//                 $headerRow.append($('<th>').text('Brand Model'));
//             }
//             $headerRow.css('background-color', '#EFF2F7');
//             $thead.append($headerRow);

//             const $tbody = $('<tbody>');
//             if (idName === "Chipslist") {
//                 rowData.switches.forEach((switchItem: any) => {
//                     const $row = $('<tr>');
//                     $row.append($('<td>').text(switchItem.displayName));
//                     $row.append($('<td>').text(switchItem.button));
//                     $row.append($('<td>').text(switchItem.isOn));
//                     $row.append($('<td>').text(switchItem.dimmerValue));
//                     $tbody.append($row);
//                 });
//             } else if (idName === "Blasterlist") {
//                 rowData.remotes.forEach((remoteItem: any) => {
//                     const $row = $('<tr>');
//                     const $toggleIcon = $('<div>').addClass('details-row-toggle1').data('remoteData', remoteItem).html('<i class="fas fa-caret-right" style="cursor: pointer;"></i>');
//                     $row.append($('<td>').append($toggleIcon));
//                     $row.append($('<td>').text(remoteItem.name));
//                     $row.append($('<td>').text(remoteItem.roomId.name));
//                     $row.append($('<td>').text(remoteItem.image));
//                     $row.append($('<td>').text(remoteItem.type));
//                     $row.append($('<td>').text(remoteItem.brandName));
//                     $row.append($('<td>').text(remoteItem.brandModal));
//                     $tbody.append($row);
//                 });
//             }
//             detailsTable.append($thead);
//             detailsTable.append($tbody);
//             return detailsTable;
//         }
//     };

//     useEffect(() => {
//         // if (!loading && tableData.length > 0) {
//         if (idName === "Chipslist" || idName === "Blasterlist") {
//             // if (selectedRooms && selectedRooms.length > 0) {
//             initializeData()
//             // }
//         }
//     }, [selectedRooms]);

//     useEffect(() => {
//         if (idName !== "Chipslist" && idName !== "Blasterlist") {
//             initializeData();
//         }
//     }, [loading]);

//     useEffect(() => {
//         // reloadDataTable();
//     }, [selectedRooms]);

//     const reloadDataTable = () => {
//         const dataTableId = $(tableRef.current).attr('id');
//         console.log("dataTableIddataTableId", dataTableId);
//         if (dataTableId && dataTableId === "dataTableChipslist") {
//             console.log("render if condition in reload table");
//             const dataTableInstance: any = $(`#${dataTableId}`).DataTable();
//             console.log("dataTableInstance", dataTableInstance);
//             // if (selectedRooms && selectedRooms.length > 0) {
//             dataTableInstance.ajax.reload(null, false);
//             // }
//         }
//     }

//     useEffect(() => {
//         const dataTableId = $(tableRef.current).attr('id');
//         onDataTableRefresh && onDataTableRefresh(dataTableId);
//     }, [])

//     // useEffect(() => {
//     //     if (dataTableInstance) {
//     //         console.log("go to data table");

//     //         // Open rows based on expandedRows array after DataTable is initialized

//     //     }
//     // }, [expandedRows]);

//     //  console.log("dataTableInstancedataTableInstance", dataTableInstance);
//     return (
//         <Card>
//             <CardBody>
//                 <div className="mb-4 h4 card-title d-flex justify-content-between flex-row" >
//                     <h5 style={{ color: '#000', fontWeight: 'bold', fontSize: 17 }}>{title}</h5>
//                     <div className="justify-content-between flex-row d-flex" style={{ gap: 20 }}>
//                         {isAddBtnShow &&
//                             <button className={btnStyle}
//                                 onClick={() => onClickBtn && onClickBtn()}
//                             >
//                                 {btnName}
//                             </button>}
//                         {isBtnShow &&
//                             <button
//                                 className={secondBtnStyle}
//                                 style={{ gap: 20 }}
//                                 onClick={() => secondBtnOnClick && secondBtnOnClick()}
//                             >
//                                 {secondBtnText}
//                             </button>}
//                     </div>
//                 </div>
//                 <div>

//                     {/* <Spinners /> */}
//                     <div id="customLoader" style={{
//                         display: 'none',
//                         position: 'fixed',
//                         top: '50%',
//                         left: '50%',
//                         transform: 'translate(-50%, -50%)',
//                         zIndex: 9999,
//                         backgroundColor: 'rgba(255, 255, 255, 0.8)',
//                         padding: '20px',
//                     }}>
//                         <p>Processing....</p>
//                     </div>
//                     {initializeTable === 0 && (
//                         initializeTable = 1,
//                         <div className='table-responsive'>
//                             <table
//                                 id={`dataTable${idName}`}
//                                 ref={tableRef}
//                                 className="table table-hover"
//                                 style={{ width: '100%', fontSize: 14, color: "#858D9D" }}
//                             >
//                             </table>
//                         </div>)}
//                 </div>
//             </CardBody>
//         </Card >
//     )
// }

// export default ProfileListDataTable