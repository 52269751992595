import React from 'react'
import { Modal, ModalBody, ModalFooter } from 'reactstrap';

export interface modelType {
    headerTitle: string,
    text: string,
    buttonText: string,
    onClick(): void,
    cancleBtnClick(): void,
    loading: boolean
}
const ConfirmationModal = ({
    headerTitle,
    text,
    buttonText,
    onClick,
    cancleBtnClick,
    loading
}: modelType) => {
    return (
        <Modal
            isOpen={true}
            // toggle={() => {
            //     tog_toggleModal();
            // }}
            centered
        >
            <div className="modal-header" style={{ justifyContent: 'center' }}>
                <h5 className="modal-title" id="staticBackdropLabel" style={{ justifyContent: 'center' }}>{headerTitle}</h5>
            </div>
            <ModalBody>
                <p style={{ textAlign: 'center' }}>{text}</p>
            </ModalBody>
            <ModalFooter>
                <button className="btn btn-outline-secondary"
                    data-bs-target="#secondmodal"
                    onClick={() => {
                        cancleBtnClick();
                        // tog_toggleModal1();
                    }}
                >
                    Cancel</button>
                <button className="btn btn-dark"
                    data-bs-target="#secondmodal"
                    onClick={() => {
                        onClick();
                    }}
                    disabled={loading && true}
                >
                    {buttonText}</button>
            </ModalFooter>
        </Modal>
    )
}

export default ConfirmationModal