import React, { useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import DataTable from '../../../components/Common/DataTable'
import * as url from '../../../helpers/url_helper';
import $, { data } from 'jquery'
import { useLocation, useNavigate } from 'react-router-dom';
import Request from '../../../helpers/request';
import ConfirmationModal from '../../../components/Common/ConfirmationModal';
import { Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { getAdvancedFilterCount, getSelectedAdvancedFilter } from '../../../store/slices/APIResponseSlice';
import UsersDataTable from '../../../components/Common/UsersDataTable';
import { DropdownDivider } from 'react-bootstrap';

const Users = () => {
    let { state } = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const selectedList = useSelector((state: any) => state.APIResponse.selectedAdvancedFilter);
    const { appOpenValue, appOperateValue, appNotOpenValue, appNotOperateValue, sceneCount, sceneRunCount, automationCount, automationRunCount } = useSelector((state: any) => state.APIResponse.advancedFilterCount);
    // console.log("advancedFilterCount", advancedFilterCount);

    console.log("selectedList", selectedList);
    console.log("state", state);


    const [loading, setLoading] = useState(false);
    const [dataTableId, setDataTableId] = useState(null);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [deleteData, setDeleteData] = useState<any>();
    const [isOpenAdvancedFilter, setOpenAdvancedFilter] = useState(false);
    const [advancedFilter, setAdvancedFilter] = useState<any>(selectedList);
    const [paramsId, setParamsId] = useState({ filter: (state?.isReload && selectedList.length > 0) ? selectedList.join(",") : "" });

    console.log("advancedFilter", paramsId);

    const reloadDataTable = () => {
        if (dataTableId) {
            const dataTableInstance: any = $(`#${dataTableId}`).DataTable();
            dataTableInstance.ajax.reload(null, false);
        }
    };

    const handleDataTableIdChange = (dataTableId: any) => {
        // Now you have access to the DataTable ID here
        //   console.log('DataTable ID:', dataTableId);
        setDataTableId(dataTableId)
        // You can do whatever you need with the ID in this component
    };

    const handleDeleteClick = (rowData: any) => {
        console.log('Delete button clicked for row data:', rowData?.id);
        setDeleteModalOpen(!isDeleteModalOpen);
        setDeleteData(rowData && rowData);
        // Add your logic here
    };

    const optionGroup = [{
        options: [
            { label: "First Name / Last Name", value: 0 },
            { label: "Mobile", value: 1 },
            { label: "Email", value: 2 },
            { label: "Device ID", value: 3 },
            { label: "Slave ID", value: 4 },
            { label: "Blaster Slave ID", value: 5 }
        ]
    }]
    const underlineStyle = 'cursor: pointer;';
    const columns = [
        {
            data: null,
            render: function (data: any, type: any, row: any) {
                const joinArray = [row.mobile, row.email].filter(Boolean)

                return `<div style="${underlineStyle} width: 350px"> 
                           <div style="color: black; fontweight: bold;">${row.firstname} ${row.lastname}</div>
                           <div>${joinArray.join(" | ")}</div>
                         </div>`
                // return `${row.name} | ${row.userId.email}`; // Combining firstname and lastname for the 'Name' column
            },
            createdCell: function (cell: any, cellData: any, rowData: any) {
                $(cell).addClass('column-click'); // Add a class to identify the column
            },
            title: 'Premise'
        },
        {
            data: null,
            createdCell: function (cell: any, cellData: any, rowData: any) {
                $(cell).addClass('column-click'); // Add a class to identify the column
            },
            title: 'No. Of Device',
            render: function (data: any, type: any, row: any) {
                return `<div style="${underlineStyle}">${row?.noOfChips
                    ? `${row?.noOfChips}` : "-"}</div>`
            },
        },
        {
            data: null,
            createdCell: function (cell: any, cellData: any, rowData: any) {
                $(cell).addClass('column-click'); // Add a class to identify the column
            },
            title: 'No. of Offline',
            render: function (data: any, type: any, row: any) {
                return `<div style="${underlineStyle}">${row?.noOfChipsOffline
                    ? `${row?.noOfChipsOffline}` : "-"}</div>`
            }
        },
        {
            data: null,
            createdCell: function (cell: any, cellData: any, rowData: any) {
                $(cell).addClass('column-click'); // Add a class to identify the column
            },
            title: "Type",
            render: function (data: any, type: any, row: any) {
                const statusBadge = row?.isOnline ? (
                    '<span class="font-size-11 badge-soft-success badge bg-secondary">Online</span>'
                ) : (
                    '<span class="font-size-11 badge-soft-danger badge bg-secondary">Offline</span>'
                );

                return `<div style="${underlineStyle}">${statusBadge}</div>`
            }
        },
        {
            data: null,
            createdCell: function (cell: any, cellData: any, rowData: any) {
                $(cell).addClass('column-click'); // Add a class to identify the column
            },
            title: 'Status',
            render: function (data: any, type: any, row: any) {
                const statusBadge = row?.isActive ? (
                    '<span class="font-size-11 badge-soft-success badge bg-secondary">Active</span>'
                ) : (
                    `<span class="font-size-11 badge-soft-danger badge bg-secondary">Inactive</span>`
                );

                return `<div style="${underlineStyle}">${statusBadge}</div>`
            }
        },
        {
            title: 'Actions',
            render: function () {
                return (
                    `<div class="d-flex gap-2">
                                <button class="delete-btn" style="border: none; outline: none; background: none;">
                                  <box-icon name='trash-alt' size="20px"></box-icon>
                                </button>
                            </div>`
                );
            }
        }
    ]

    // <button class="column-click" style="border: none; outline: none; background: none;"><span class="badge bg-primary" style="height: 25px; width: 60px; font-size: 12px; padding: 6px;">Detail</span></button>
    const deleteUserApiCall = () => {
        setLoading(true);
        const reqObj = {
            userId: deleteData.id
        }
        console.log("reqObj in users", reqObj);

        Request.post(url.DELETE_USER, reqObj).
            then((res) => {
                // console.log("delete Area Response", res);
                // areaListApiCall()

                toast.success(res.data.msg)
                reloadDataTable();
                setDeleteModalOpen(false)
                setLoading(false)
            }).
            catch((err) => {
                // console.log("edit area Error", err);
                toast.error(err.response.data.errors[0].msg)
                setLoading(false)
                // showToast("error", err.response.data.errors[0]?.msg)
            })
    }

    const handleDetailsClick = (rowData: any) => {
        console.log("details data", rowData);
        rowData?.id ?
            navigate(`/users/profile/${rowData.id}`) : ""
    }

    const toggleAdvanceFilter = () => {
        setOpenAdvancedFilter(!isOpenAdvancedFilter)
    }

    const handleAdvancedFilter = (name?: any, value?: string) => {
        console.log("sdfgdfhbgdfhfgh", name);
        // var list = [];
        var selectedList1 = [];

        if (selectedList.includes(value)) {
            console.log("selectedList.includes()",);

            const filterData = selectedList.filter((e: string) => e != value)
            console.log("filterDatafilterData", filterData);
            dispatch(getSelectedAdvancedFilter(filterData))
            // setAdvancedFilter(filterData)
        } else if (selectedList.some((item: any) => item?.includes(name?.target?.name ? name?.target?.name : name))) {
            const filterData = selectedList.filter((e: string) => !e.includes(name?.target?.name ? name?.target?.name : name))
            filterData.push(value)
            dispatch(getSelectedAdvancedFilter(filterData))
        }
        else {
            selectedList1.push(...advancedFilter, value)
            dispatch(getSelectedAdvancedFilter([...selectedList, value]))
            // setAdvancedFilter([...advancedFilter, value])
        }
        // console.log("selectedList", selectedList1);
        updatedParamsId()
    }

    const updatedParamsId = () => {
        setParamsId({ filter: selectedList.join(",") });
    }

    const resetAdvanceFilterCount = () => {
        dispatch(getAdvancedFilterCount({
            appOpenValue: "1",
            appOperateValue: "1",
            appNotOpenValue: "1",
            appNotOperateValue: "1",
            sceneCount: "1",
            sceneRunCount: "1",
            automationCount: "1",
            automationRunCount: "1"
        }))
    }

    useEffect(() => {
        if (!state?.isReload) {
            dispatch(getSelectedAdvancedFilter([]))
            resetAdvanceFilterCount()
        }
    }, [])

    const handleAdvancedFilterCount = (name: string, e: any) => {
        console.log("sdfgdfhbg", name);

        if (Number(e.target.value) < 0) {
            dispatch(getAdvancedFilterCount({ [e.target.name]: "1" }))
        } else if (Number(e.target.value) <= 999) {
            dispatch(getAdvancedFilterCount({ [e.target.name]: e.target.value }))
        }
        if (selectedList.some((item: string) => item.includes(name))) {
            handleAdvancedFilter(name, `${name}-${e.target.value}`)
        }
    }
    // useEffect(() => {
    //     reloadDataTable();
    // }, [paramsId])

    return (
        <React.Fragment>
            <div>
                <Modal
                    isOpen={isOpenAdvancedFilter}
                    role="dialog"
                    autoFocus={true}
                    centered={true}
                    className="exampleModal"
                    fullscreen={'md'}
                    size='lg'
                    // tabIndex="-1"
                    toggle={toggleAdvanceFilter}
                >
                    <div className="modal-content">
                        <ModalHeader style={{
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                            toggle={toggleAdvanceFilter}
                            close={<button
                                className="btn btn-danger"
                                type="submit"
                                disabled={loading}
                                onClick={() => {
                                    setParamsId({ filter: "" })

                                    dispatch(getSelectedAdvancedFilter([]));
                                    resetAdvanceFilterCount()
                                    // updatedParamsId();
                                    toggleAdvanceFilter();
                                    // reloadDataTable()
                                    // setAdvancedFilter([]);

                                }}
                            >
                                Clear All
                            </button>}
                        >
                            Advanced Filter
                        </ModalHeader>
                        <ModalBody>
                            {/* ON BORDERD */}
                            <table>
                                <tbody>
                                    <tr>
                                        <th style={{ width: 120 }}>On Borderd</th>
                                        <tr>
                                            <input
                                                type="checkbox"
                                                id="onBorderdNoDevice"
                                                name="onBorderdNoDevice"
                                                style={{ marginRight: 10 }}
                                                checked={selectedList.includes("onBorderdNoDevice")}
                                                onChange={(e) => handleAdvancedFilter(e, "onBorderdNoDevice")}
                                            />
                                            <label htmlFor="onBorderdNoDevice">No device</label>
                                        </tr>
                                        <tr>
                                            <input
                                                type="checkbox"
                                                id="onBorderdNotAssignedRoom"
                                                name="onBorderdNotAssignedRoom"
                                                style={{ marginRight: 10 }}
                                                checked={selectedList.includes("onBorderdNotAssignedRoom")}
                                                onChange={(e) => handleAdvancedFilter(e, "onBorderdNotAssignedRoom")}
                                            />
                                            <label htmlFor="onBorderdNotAssignedRoom">Not Assigned room (Has device)</label></tr>
                                        <tr>
                                            <input
                                                type="checkbox"
                                                id="onBorderdAssignedRoom"
                                                name="onBorderdAssignedRoom"
                                                style={{ marginRight: 10 }}
                                                checked={selectedList.includes("onBorderdAssignedRoom")}
                                                onChange={(e) => handleAdvancedFilter(e, "onBorderdAssignedRoom")}
                                            />
                                            <label htmlFor="onBorderdAssignedRoom">Assigned Room
                                            </label>
                                        </tr>
                                    </tr>
                                </tbody>
                            </table>
                            <hr />

                            {/* DATA UPDATE */}
                            <table style={{ marginTop: 18 }}>
                                <tbody>
                                    <tr>
                                        <th style={{ width: 120 }}>Data Update</th>
                                        <tr>
                                            <input
                                                type="checkbox"
                                                id="dataUpdateName"
                                                name="dataUpdateName"
                                                style={{ marginRight: 10 }}
                                                checked={selectedList.includes("dataUpdateName")}
                                                onChange={(e) => handleAdvancedFilter(e, "dataUpdateName")}
                                            />
                                            <label htmlFor="dataUpdateName">
                                                Name
                                            </label>
                                        </tr>
                                        <tr>
                                            <input
                                                type="checkbox"
                                                id="dataUpdateEmail"
                                                name="dataUpdateEmail"
                                                style={{ marginRight: 10 }}
                                                checked={selectedList.includes("dataUpdateEmail")}
                                                onChange={(e) => handleAdvancedFilter(e, "dataUpdateEmail")}
                                            />
                                            <label htmlFor="dataUpdateEmail">
                                                Email
                                            </label>
                                        </tr>
                                        <tr>
                                            <input
                                                type="checkbox"
                                                id="dataUpdateProfilePhoto"
                                                name="dataUpdateProfilePhoto"
                                                style={{ marginRight: 10 }}
                                                checked={selectedList.includes("dataUpdateProfilePhoto")}
                                                onChange={(e) => handleAdvancedFilter(e, "dataUpdateProfilePhoto")}
                                            />
                                            <label htmlFor="dataUpdateProfilePhoto">
                                                Profile Photo
                                            </label>
                                        </tr>
                                    </tr>
                                </tbody>
                            </table>
                            <hr />

                            {/* USING */}
                            <table style={{ marginTop: 18 }}>
                                <tbody>
                                    <tr>
                                        <th style={{ width: 120 }}>Using</th>
                                        <tr>
                                            <input
                                                type="checkbox"
                                                id="usingOnline"
                                                name="usingOnline"
                                                style={{ marginRight: 10 }}
                                                checked={selectedList.includes("usingOnline")}
                                                onChange={(e) => handleAdvancedFilter(e, "usingOnline")}
                                            />
                                            <label htmlFor="usingOnline">
                                                Online
                                            </label>
                                        </tr>
                                        <tr>
                                            <input
                                                type="checkbox"
                                                id={`usingAppOpen`}
                                                name={`usingAppOpen`}
                                                style={{ marginRight: 10 }}
                                                checked={selectedList.includes(`usingAppOpen-${appOpenValue}`)}
                                                onChange={(e) => handleAdvancedFilter(e, `usingAppOpen-${appOpenValue}`)}
                                                disabled={!appOpenValue || Number(appOpenValue) <= 0}
                                            />
                                            <label htmlFor={`usingAppOpen`}>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    Application open daily for more than
                                                    <Input
                                                        name='appOpenValue'
                                                        value={appOpenValue}
                                                        onChange={(e) => handleAdvancedFilterCount("usingAppOpen", e)}
                                                        // onChange={(e) => setAppOpenValue(Number(e.target.value) < 0 ? "1" : e.target.value)}
                                                        style={{ width: 80, height: 25, marginLeft: '0.25rem', marginRight: '0.25rem' }}
                                                        max={999}
                                                        min={0}
                                                        type='number'

                                                    />
                                                    days
                                                </div>
                                            </label>
                                        </tr>
                                        <tr>
                                            <input
                                                type="checkbox"
                                                id={`usingAppOperate-${appOperateValue}`}
                                                name={`usingAppOperate-${appOperateValue}`}
                                                style={{ marginRight: 10 }}
                                                checked={selectedList.includes(`usingAppOperate-${appOperateValue}`)}
                                                onChange={(e) => handleAdvancedFilter(e, `usingAppOperate-${appOperateValue}`)}
                                                disabled={!appOperateValue || Number(appOperateValue) <= 0}
                                            />
                                            <label htmlFor={`usingAppOperate-${appOperateValue}`}>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    Application operate daily for more than
                                                    <Input
                                                        name="appOperateValue"
                                                        value={appOperateValue}
                                                        onChange={(e) => handleAdvancedFilterCount("usingAppOperate", e)}
                                                        // onChange={(e) => setAppOperateValue(Number(e.target.value) < 0 ? "1" : e.target.value)}
                                                        style={{ width: 80, height: 25, marginLeft: '0.25rem', marginRight: '0.25rem' }}
                                                        max={999}
                                                        min={0}
                                                        type='number'
                                                    />
                                                    days
                                                </div>
                                            </label>
                                        </tr>
                                        <tr>
                                            <input
                                                type="checkbox"
                                                id={`usingAppNotOpen-${appNotOpenValue}`}
                                                name={`usingAppNotOpen`}
                                                style={{ marginRight: 10 }}
                                                checked={selectedList.includes(`usingAppNotOpen-${appNotOpenValue}`)}
                                                onChange={(e) => handleAdvancedFilter(e, `usingAppNotOpen-${appNotOpenValue}`)}
                                                disabled={!appNotOpenValue || Number(appNotOpenValue) <= 0}
                                            />
                                            <label htmlFor={`usingAppNotOpen-${appNotOpenValue}`}>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    Application not open for more than
                                                    <Input
                                                        name="appNotOpenValue"
                                                        value={appNotOpenValue}
                                                        onChange={(e) => handleAdvancedFilterCount("usingAppNotOpen", e)}
                                                        // onChange={(e) => setAppNotOpenValue(Number(e.target.value) < 0 ? "1" : e.target.value)}
                                                        style={{ width: 80, height: 25, marginLeft: '0.25rem', marginRight: '0.25rem' }}
                                                        max={999}
                                                        min={0}
                                                        type='number'
                                                    />
                                                    days
                                                </div>
                                            </label>
                                        </tr>
                                        <tr>
                                            <input
                                                type="checkbox"
                                                id={`usingAppNotOperate-${appNotOperateValue}`}
                                                name={`usingAppNotOperate`}
                                                style={{ marginRight: 10 }}
                                                checked={selectedList.includes(`usingAppNotOperate-${appNotOperateValue}`)}
                                                onChange={(e) => handleAdvancedFilter(e, `usingAppNotOperate-${appNotOperateValue}`)}
                                                disabled={!appNotOperateValue || Number(appNotOperateValue) <= 0}
                                            />
                                            <label htmlFor={`usingAppNotOperate-${appNotOperateValue}`}>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    Application not operate for more than
                                                    <Input
                                                        name='appNotOperateValue'
                                                        value={appNotOperateValue}
                                                        onChange={(e) => handleAdvancedFilterCount("usingAppNotOperate", e)}
                                                        // onChange={(e) => setAppNotOperateValue(Number(e.target.value) < 0 ? "1" : e.target.value)}
                                                        style={{ width: 80, height: 25, marginLeft: '0.25rem', marginRight: '0.25rem' }}
                                                        max={999}
                                                        min={0}
                                                        type='number'
                                                    />
                                                    days
                                                </div>
                                            </label>
                                        </tr>
                                    </tr>
                                </tbody>
                            </table>
                            <hr />

                            {/* AUTOMATION */}
                            <table style={{ marginTop: 18 }}>
                                <tbody>
                                    <tr>
                                        <th style={{ width: 120 }}>Automation</th>
                                        <tr>
                                            <input
                                                type="checkbox"
                                                id="automationSceneNotCompleted"
                                                name="automationSceneNotCompleted"
                                                style={{ marginRight: 10 }}
                                                checked={selectedList.includes("automationSceneNotCompleted")}
                                                onChange={(e) => handleAdvancedFilter(e, "automationSceneNotCompleted")}
                                            />
                                            <label htmlFor="automationSceneNotCompleted">
                                                Scene Creation Started but not complete
                                            </label>
                                        </tr>
                                        <tr>
                                            <input
                                                type="checkbox"
                                                id={`automationSceneCount-${sceneCount}`}
                                                name={`automationSceneCount`}
                                                style={{ marginRight: 10 }}
                                                checked={selectedList.includes(`automationSceneCount-${sceneCount}`)}
                                                onChange={(e) => handleAdvancedFilter(e, `automationSceneCount-${sceneCount}`)}
                                                disabled={!sceneCount || Number(sceneCount) <= 0}
                                            />
                                            <label htmlFor={`automationSceneCount-${sceneCount}`}>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    Scene Count :
                                                    <Input
                                                        name='sceneCount'
                                                        value={sceneCount}
                                                        onChange={(e) => handleAdvancedFilterCount("automationSceneCount", e)}
                                                        // onChange={(e) => setSceneCount(Number(e.target.value) < 0 ? "1" : e.target.value)}
                                                        style={{ width: 80, height: 25, marginLeft: '0.25rem', marginRight: '0.25rem' }}
                                                        max={999}
                                                        min={0}
                                                        type='number'
                                                    />
                                                </div>
                                            </label>
                                        </tr>
                                        <tr>
                                            <input
                                                type="checkbox"
                                                id={`automationSceneRunCount-${sceneRunCount}`}
                                                name={`automationSceneRunCount`}
                                                style={{ marginRight: 10 }}
                                                checked={selectedList.includes(`automationSceneRunCount-${sceneRunCount}`)}
                                                onChange={(e) => handleAdvancedFilter(e, `automationSceneRunCount-${sceneRunCount}`)}
                                                disabled={!sceneRunCount || Number(sceneRunCount) <= 0}
                                            />
                                            <label htmlFor={`automationSceneRunCount-${sceneRunCount}`}>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    Scene Operated Count :
                                                    <Input
                                                        name='sceneRunCount'
                                                        value={sceneRunCount}
                                                        onChange={(e) => handleAdvancedFilterCount("automationSceneRunCount", e)}
                                                        // onChange={(e) => setSceneRunCount(Number(e.target.value) < 0 ? "1" : e.target.value)}
                                                        style={{ width: 80, height: 25, marginLeft: '0.25rem', marginRight: '0.25rem' }}
                                                        max={999}
                                                        min={0}
                                                        type='number'
                                                    />
                                                </div>
                                            </label>
                                        </tr>
                                        <tr>
                                            <input
                                                type="checkbox"
                                                id="automationNotCompleted"
                                                name="automationNotCompleted"
                                                style={{ marginRight: 10 }}
                                                checked={selectedList.includes("automationNotCompleted")}
                                                onChange={(e) => handleAdvancedFilter(e, "automationNotCompleted")}
                                            />
                                            <label htmlFor="automationNotCompleted">
                                                Automation Creation Started but not complete
                                            </label>
                                        </tr>
                                        <tr>
                                            <input
                                                type="checkbox"
                                                id={`automationCount-${automationCount}`}
                                                name={`automationCount`}
                                                style={{ marginRight: 10 }}
                                                checked={selectedList.includes(`automationCount-${automationCount}`)}
                                                onChange={(e) => handleAdvancedFilter(e, `automationCount-${automationCount}`)}
                                                disabled={!automationCount || Number(automationCount) <= 0}
                                            />
                                            <label htmlFor={`automationCount-${automationCount}`}>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    Automation Count :
                                                    <Input
                                                        name='automationCount'
                                                        value={automationCount}
                                                        onChange={(e) => handleAdvancedFilterCount("automationCount", e)}
                                                        // onChange={(e) => setAutomationCount(Number(e.target.value) < 0 ? "1" : e.target.value)}
                                                        style={{ width: 80, height: 25, marginLeft: '0.25rem', marginRight: '0.25rem' }}
                                                        max={999}
                                                        min={0}
                                                        type='number'
                                                    />
                                                </div>
                                            </label>
                                        </tr>
                                        <tr>
                                            <input
                                                type="checkbox"
                                                id={`automationRunCount-${automationRunCount}`}
                                                name={`automationRunCount`}
                                                style={{ marginRight: 10 }}
                                                checked={selectedList.includes(`automationRunCount-${automationRunCount}`)}
                                                onChange={(e) => handleAdvancedFilter(e, `automationRunCount-${automationRunCount}`)}
                                                disabled={!automationRunCount || Number(automationRunCount) <= 0}
                                            />
                                            <label htmlFor={`automationRunCount-${automationRunCount}`}>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    Automation Run Count :
                                                    <Input
                                                        name='automationRunCount'
                                                        value={automationRunCount}
                                                        onChange={(e) => handleAdvancedFilterCount("automationRunCount", e)}
                                                        // onChange={(e) => setAutomationRunCound(Number(e.target.value) < 0 ? "1" : e.target.value)}
                                                        style={{ width: 80, height: 25, marginLeft: '0.25rem', marginRight: '0.25rem' }}
                                                        max={999}
                                                        min={0}
                                                        type='number'
                                                    />
                                                </div>
                                            </label>
                                        </tr>
                                    </tr>
                                </tbody>
                            </table>
                            <hr />

                            {/* SHARED */}
                            <table style={{ marginTop: 18 }}>
                                <tbody>
                                    <tr>
                                        <th style={{ width: 120 }}>Shared</th>
                                        <tr>
                                            <input
                                                type="checkbox"
                                                id="shared"
                                                name="shared"
                                                style={{ marginRight: 10 }}
                                                checked={selectedList.includes("shared")}
                                                onChange={(e) => handleAdvancedFilter(e, "shared")}
                                            />
                                            <label htmlFor="shared">
                                                Shared
                                            </label>
                                        </tr>
                                        <tr>
                                            <input
                                                type="checkbox"
                                                id="sharednot"
                                                name="sharednot"
                                                style={{ marginRight: 10 }}
                                                checked={selectedList.includes("sharednot")}
                                                onChange={(e) => handleAdvancedFilter(e, "sharednot")}
                                            />
                                            <label htmlFor="sharednot">
                                                Not Share
                                            </label>
                                        </tr>
                                        <tr>
                                            <input
                                                type="checkbox"
                                                id="share2DeviceNotShared"
                                                name="share2DeviceNotShared"
                                                style={{ marginRight: 10 }}
                                                checked={selectedList.includes("share2DeviceNotShared")}
                                                onChange={(e) => handleAdvancedFilter(e, "share2DeviceNotShared")}
                                            />
                                            <label htmlFor="share2DeviceNotShared">
                                                Has 2 device and not shared
                                            </label>
                                        </tr>
                                    </tr>
                                </tbody>
                            </table>
                        </ModalBody>
                        {/* <ModalFooter style={{ justifyContent: 'right' }}>
                            <button
                                className="btn btn-danger"
                                type="submit"
                                disabled={loading}
                                onClick={() => {
                                    setParamsId({ filter: "" })

                                    dispatch(getSelectedAdvancedFilter([]));
                                    // updatedParamsId();
                                    toggleAdvanceFilter();
                                    // reloadDataTable()
                                    // setAdvancedFilter([]);

                                }}
                            >
                                Clear All
                            </button>
                            <button
                                className="btn btn-dark"
                                type="submit"
                                disabled={loading}
                                onClick={() => {
                                    updatedParamsId();
                                    toggleAdvanceFilter();
                                    // reloadDataTable()
                                }}
                            >
                                Apply Filter
                            </button>
                        </ModalFooter> */}
                    </div>
                </Modal>
                {
                    isDeleteModalOpen &&
                    <ConfirmationModal
                        headerTitle="Delete User?"
                        text="Are you sure you want to delete this User ? This action cannot be undone."
                        buttonText={loading ? "Deleting..." : "Delete"}
                        onClick={() => { deleteUserApiCall() }}
                        cancleBtnClick={() => setDeleteModalOpen(false)}
                        loading={loading}
                    />
                }
                <ToastContainer />
                <UsersDataTable
                    // data={servicePersonList}
                    apiURL={`${url.BASE_URL}${url.GET_USERS_DETAIL_URL}`}
                    paramsId={{ filter: selectedList?.join(",") }}
                    columns={columns}
                    isAddBtnShow={true}
                    // isBtnShow={false}
                    btnName={`Advance Filter ${selectedList.length !== 0 ? `/ ${selectedList.length}` : ""}`}
                    btnStyle="btn btn-dark"
                    onClickBtn={toggleAdvanceFilter}
                    handleColumnClicked={handleDetailsClick}
                    handleDeleteClick={handleDeleteClick}
                    // editApiCall={() => { }}
                    onDataTableRefresh={handleDataTableIdChange}
                    idName={"Users"}
                    isEntryShow={true}
                    showFilterOpetions={true}
                    optionGroup={optionGroup}
                    isClicked={true}
                    isReload={state?.isReload}
                />
            </div >

        </React.Fragment >
    )
}

export default Users;