import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import { Card, CardBody, CardText, Col, Form, Label, Modal, ModalBody, ModalFooter, ModalHeader, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap'
import Breadcrumb from '../../../../components/Common/Breadcrumb';
import Request from '../../../../helpers/request';
import * as url from '../../../../helpers/url_helper';
import { convertLocalDate } from '../../../../Utils/configController';
import ChipsList from './ChipsDetail/ChipsList';
import BlasterList from './BlasterList';
import ScheduleList from './ScheduleList';
import DeviceList from './DeviceList';
import { DropdownDivider } from 'react-bootstrap';
import { Images } from '../../../../constants/images';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { selectedPremiseRooms } from '../../../../store/slices/APIResponseSlice';
import { useSelector } from 'react-redux';
import UserAction from './UserAction';
import $ from 'jquery';

const ProfileList = () => {
    const params = useParams();
    const dispatch = useDispatch();
    const selectedRooms = useSelector((state: any) => state.APIResponse.selectedRooms);
    console.log("selectedRooms", selectedRooms);

    const [loading, setLoading] = useState<boolean>(false);
    const [profileDetails, setProfileDetails] = useState<any[]>([]);
    const [deviceDetails, setDeviceDetails] = useState<any[]>([]);
    const [chipList, setChipList] = useState<any[]>([]);
    const [premiseList, setPremiseList] = useState<any[]>([]);
    const [selectedPremise, setSelectedPremise] = useState<any>("");
    const [isOpenProModal, setOpenProModal] = useState<boolean>(false);
    // const [selectedPremiseRooms, setSelectedPremiseRooms] = useState<any[]>([]);

    const [roomList, setRoomList] = useState<any[]>([]);
    const [activeTab, setActiveTab] = useState("0")
    console.log("selectedPremise", selectedPremise?.id);

    const changeProPremise = () => {
        setLoading(true);
        const reqObj = {
            premiseId: selectedPremise?.id,
            isPro: selectedPremise?.isPro ? false : true
        }

        Request.post(url.CHANGE_PREMISE_PRO, reqObj).then((res) => {
            getProfileListDetail()
            setLoading(false)
            toggleProModal()
            toast.success(res.data.msg);
        }).catch((err) => {
            setLoading(false)
        })
    }

    const getProfileListDetail = () => {

        setLoading(true);
        const reqObj = {
            userId: params.id,
        }
        console.log("reqObj", reqObj);

        Request.post(url.GET_PROFILE_LIST_DETAILS, reqObj).
            then((res) => {
                console.log("get profile list Response", res);
                const data = res.data;
                setProfileDetails([
                    {
                        name: "User Id",
                        data: `#${data?.data?.id}`
                    },
                    {
                        name: "Name",
                        data: `${data?.data?.firstname} ${data?.data?.lastname}`
                    },
                    {
                        name: "Email",
                        data: data?.data?.email ? data?.data?.email : "--"
                    },
                    {
                        name: "Mobile",
                        data: data?.data?.mobile ? data?.data?.mobile : "--"
                    },
                    {
                        name: "Address",
                        data: data?.data?.address ? data?.data?.address : "--"
                    },
                    {
                        name: "Created At",
                        data: data?.data?.createdAt ? convertLocalDate(data?.data?.createdAt) : "--"
                    },
                    {
                        name: "Last login",
                        data: data?.data?.lastLogin ? convertLocalDate(data?.data?.lastLogin) : "--"
                    },
                    {
                        name: "Last Action",
                        data: data?.data?.lastAction ? convertLocalDate(data?.data?.lastAction) : "--"
                    },
                    {
                        name: "Last Online Time",
                        data: data?.data?.lastOnlineTime ? convertLocalDate(data?.data?.lastOnlineTime) : "--"
                    },
                    {
                        name: "Last Offline Time",
                        data: data?.data?.lastOfflineTime ? convertLocalDate(data?.data?.lastOfflineTime) : "--"
                    },
                ])
                setDeviceDetails([
                    {
                        name: "Total Devices",
                        icon: Images.TotalDeviceIcon,
                        data: data?.onlineChips || data?.offlineChips ? data?.onlineChips + data?.offlineChips : "--"
                    },
                    {
                        name: "Online Devices",
                        icon: Images.OnlineIcon,
                        data: data?.onlineChips ? data?.onlineChips : "--"
                    },
                    {
                        name: "Offline Devices",
                        icon: Images.OfflineIcon,
                        data: data?.offlineChips ? data?.offlineChips : "--"
                    },
                    {
                        name: "Max Signal",
                        icon: Images.OnlineIcon,
                        data: data?.maxSignalStrength ? data?.maxSignalStrength : "--"
                    },
                    {
                        name: "Min Signal",
                        icon: Images.OfflineIcon,
                        data: data?.minSignalStrength ? data?.minSignalStrength : "--"
                    },
                ])
                setPremiseList(data?.premises)
                if (!selectedPremise) {
                    setSelectedPremise(data?.premises[0])
                } else {
                    if (data?.premises?.filter((premise: any) => premise?.id === selectedPremise?.id)) {
                        const premise = data?.premises?.filter((premise: any) => premise?.id === selectedPremise?.id)
                        setSelectedPremise(premise[0])
                    }
                }
                const rooms = [...(data?.rooms || [])]; // Ensure data?.rooms is an array or initialize as an empty array if undefined
                const firstPremiseId = data?.premises.length ? data.premises[0].id : null; // Get the id of the first premise if available

                // Add "No Room" entry at the beginning of the array
                // rooms.unshift({ id: "noroom", name: "No Room", premiseId: firstPremiseId });
                setRoomList(rooms)
                // setRoomList([...data?.rooms, { id: "noroom", name: "No Room", premiseId: data?.premises[0].id }])
                setLoading(false)
            }).
            catch((err) => {
                setLoading(false)
            })
    }

    useEffect(() => {
        getProfileListDetail();
    }, [])

    const toggle1 = (tab: any) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    const handlePremises = (e: any) => {
        console.log("selected value", e.target.value);
        const selectedPremise = premiseList.find((item: any) => item.id === e.target.value);
        setSelectedPremise(selectedPremise)
    }

    useEffect(() => {
        console.log("room List", roomList);
        const rooms = roomList
        const filteredRooms = rooms.filter((rooms) => rooms.name !== "No Room")
        filteredRooms.unshift({
            name: "No Room",
            id: "noroom",
            premiseId: selectedPremise?.id
        })
        dispatch(selectedPremiseRooms(filteredRooms.filter((id) => id.premiseId === selectedPremise?.id).map((item, index) => {
            return (
                { ...item, isChecked: true }
            )
        })))
    }, [selectedPremise?.id])

    const handleRoomChecked = (checkedItem: any) => {
        console.log("checkedItem", checkedItem);
        dispatch(selectedPremiseRooms(selectedRooms.map((item: any, index: number) => {
            if (item.id === checkedItem.id) {
                return { ...item, isChecked: !item.isChecked }
            } else {
                return { ...item }
            }
        })))
    }

    const toggleProModal = () => {
        setOpenProModal(!isOpenProModal)
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <div style={{ marginTop: 20 }}>
                    <Breadcrumb
                        title="Users"
                        breadcrumbItem={"Profile List"}
                        state={{ isReload: true }}
                        titleClick={"/users"}
                    />
                </div>
                <ToastContainer />
                <Card>
                    <CardBody>
                        <Row>
                            {profileDetails.map((item: any, index: number) => {
                                return (
                                    <Col key={index} lg={2}>
                                        <CardBody>
                                            <p style={{ color: "#858D9D", fontSize: 14 }}>{item?.name}</p>
                                            <h4 style={{ fontSize: 16, }}>{item?.data}</h4>
                                        </CardBody>
                                    </Col>
                                )
                            })}
                        </Row>
                        <hr></hr>
                        <Row>
                            <Col>
                                <Row>
                                    {deviceDetails.map((deviceItem: any, index: number) => {
                                        return (
                                            <Col body key={index}>
                                                <Card style={{ border: "1px solid #EAECF0", height: '80%' }}>
                                                    <CardBody>
                                                        <img src={deviceItem?.icon} className="float-end" style={{ height: 26.94 }} />
                                                        <h1 style={{ color: "#5D6679", fontWeight: 500, fontSize: 24 }}>
                                                            {deviceItem?.data}
                                                        </h1>
                                                        <p style={{ fontSize: 18, color: "#858D9D" }}>{deviceItem?.name}</p>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        )
                                    })}

                                </Row>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                <Row style={{ marginBottom: 20 }}>
                    <Col>
                        <Nav className="rounded-pills" style={{ backgroundColor: "#F1F1F1", width: "fit-content", borderRadius: 50 }}>
                            <NavItem>
                                <NavLink
                                    style={{
                                        cursor: "pointer",
                                        borderRadius: 50,
                                        backgroundColor: activeTab === "0" ? "#fff" : "#F1F1F1",
                                        color: "#000",
                                        fontSize: 16,
                                        fontWeight: activeTab === "0" ? 500 : 300
                                    }}
                                    className={classNames({
                                        active: activeTab === "0",
                                        "custom-active-class": activeTab === "0"
                                    })}
                                    onClick={() => {
                                        toggle1("0");
                                    }}
                                >
                                    Overview
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    style={{
                                        cursor: "pointer",
                                        borderRadius: 50,
                                        backgroundColor: activeTab === "1" ? "#fff" : "#F1F1F1",
                                        color: "#000",
                                        fontSize: 16,
                                        fontWeight: activeTab === "1" ? 500 : 300
                                    }}
                                    className={classNames({
                                        active: activeTab === "1",
                                        "custom-active-class": activeTab === "1"
                                    })}
                                    onClick={() => {
                                        toggle1("1");
                                    }}
                                >
                                    Logs
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </Col>
                </Row>
                <TabContent
                    activeTab={activeTab}
                    className="p-3 text-muted"
                >
                    <TabPane tabId="0">
                        <Row style={{ marginBottom: 20 }}>
                            <Col lg={3}>
                                <div className="mb-3">
                                    {/* <label htmlFor="client_type" className="form-label">Select Client Type</label> */}
                                    <select className="form-control" onChange={handlePremises}>
                                        {premiseList?.map((item: any, index: number) => {
                                            return (
                                                <option value={item?.id} key={index}>{item.name}</option>
                                            )
                                        })}
                                    </select>
                                    {selectedPremise?.isPro ?
                                        <div style={{ display: "flex", justifyContent: "space-between", paddingTop: 5, alignItems: "center" }}
                                        >
                                            <span className='font-size-11 badge-soft-success badge bg-secondary'>This premise is Pro.</span>
                                            <span onClick={() => setOpenProModal(true)} className='btn btn-sm btn-dark'>Remove Pro</span>
                                        </div> :
                                        <div style={{ display: "flex", justifyContent: "space-between", paddingTop: 5, alignItems: "center" }}
                                        >
                                            <span className='font-size-11 badge-soft-danger badge bg-secondary'>This premise is not Pro.</span>
                                            <span onClick={() => setOpenProModal(true)} className='btn btn-sm btn-dark'>Mark as Pro</span>
                                        </div>
                                    }
                                </div>
                            </Col>
                            <Col lg={9} style={{ alignItems: "center" }}>
                                <Row>
                                    {/* <Col lg={2} style={{ fontSize: 14, cursor: "pointer" }} onClick={() => handleRoomChecked("noroom")}>
                                <input type="checkbox" style={{ marginRight: 10 }} checked={false} />{"No Rooms"}
                            </Col> */}
                                    {selectedRooms.map((item: any, index: number) => {
                                        return (
                                            // <div style={{ fontSize: 16 }} key={index}>
                                            <Col key={index} lg={2} style={{ fontSize: 14, cursor: "pointer" }} onClick={() => handleRoomChecked(item)}>
                                                <input type='checkbox' style={{ marginRight: 10 }} checked={item.isChecked} />{item.name}
                                            </Col>
                                            // </div>
                                        )
                                    })}
                                </Row>
                                {/* </Col> */}
                            </Col>
                        </Row>
                        <ChipsList
                            userId={params.id}
                        />
                        <BlasterList
                            userId={params.id}
                        />
                        <ScheduleList
                            userId={params.id}
                        />
                        <DeviceList
                            userId={params.id}
                        />
                    </TabPane>
                    <TabPane tabId={"1"}>
                        <UserAction
                            userId={params.id}
                        />
                    </TabPane>
                </TabContent>
                <Modal
                    isOpen={isOpenProModal}
                    role="dialog"
                    autoFocus={true}
                    centered={true}
                    className="exampleModal"
                    // tabIndex="-1"
                    toggle={toggleProModal}
                >
                    <div className="modal-content">
                        <ModalHeader style={{ justifyContent: 'center' }}>{selectedPremise?.isPro ? "Remove Pro" : "Mark As Pro"}</ModalHeader>

                        <ModalBody>
                            <Label>{`Are you sure you want to ${selectedPremise?.isPro ? "remove Pro from this premise?" : "mark this premise as Pro?"}`}</Label>
                        </ModalBody>
                        <ModalFooter style={{ justifyContent: 'right' }}>
                            <button className="btn btn-outline-secondary"
                                data-bs-target="#secondmodal"
                                onClick={() => {
                                    toggleProModal();
                                    // tog_toggleModal1();
                                }}
                            >
                                Cancel</button>
                            <button className="btn btn-dark"
                                data-bs-target="#secondmodal"
                                onClick={() => {
                                    changeProPremise();
                                }}
                                disabled={loading && loading}
                            >
                                {selectedPremise?.isPro ? "Remove Pro" : "Mark As Pro"}
                            </button>
                        </ModalFooter>
                    </div>
                </Modal>
            </div >

        </React.Fragment >
    )
}

export default ProfileList